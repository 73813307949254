import { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function Navbar() {
  const [t, i18n] = useTranslation("global");

  const changeLanguage = (lng) => {
    localStorage.setItem("language", lng);
    i18n.changeLanguage(lng);
  };

  useEffect(() => {
    const storedLanguage = localStorage.getItem("language");
    if (storedLanguage) {
      i18n.changeLanguage(storedLanguage);
    }
  }, [i18n]);

  const [isNavOpen, setIsNavOpen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsNavOpen(window.innerWidth >= 768); // Show navigation on tablets and larger devices
    };

    // Set initial state based on window width
    handleResize();

    // Add event listener for resize
    window.addEventListener("resize", handleResize);

    // Cleanup function to remove event listener when component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Function to handle toggling of navigation menu
  const toggleNav = () => {
    setIsNavOpen(!isNavOpen);
  };

  // Function to handle smooth scrolling
  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  const Login = () => {
    return (
      <NavLink
        to="/login"
        className="lg:inline-flex lg:w-auto w-full px-3 py-2 rounded text-white items-center justify-center hover:bg-gray-900 hover:text-[#fcab21]"
      >
        {t("Login.text")}
      </NavLink>
    );
  };

  return (
    <nav className="flex items-center bg-gray-900 pr-6 flex-wrap border-b-4 border-[#fcab21]">
      <NavLink to="/" className="mr-4 inline-flex items-center">
        <img
          src={new URL("../assets/images/logo.png", import.meta.url).href}
          alt="Footer Logo"
          className="pl-14 pt-4 h-14 mb-2 md:mt-0 md:mb-4"
        />
      </NavLink>

      <button
        className={`hamburger-menu md:hidden p-3 hover:bg-gray-900 rounded lg:hidden ml-auto hover:text-[#fcab21] outline-none nav-toggler ${
          isNavOpen ? "active" : ""
        }`}
        onClick={toggleNav}
      >
        <span
          id="bar1"
          className={`block h-1 w-6 rounded bg-white mb-1 ${
            isNavOpen ? "bg-yellow-500" : ""
          }`}
        ></span>
        <span
          id="bar2"
          className={`block h-1 w-6 rounded bg-white mb-1 ${
            isNavOpen ? "bg-yellow-500" : ""
          }`}
        ></span>
        <span
          id="bar3"
          className={`block h-1 w-6 rounded bg-white ${
            isNavOpen ? "bg-yellow-500" : ""
          }`}
        ></span>
      </button>
      <div
        className={`top-navbar w-full lg:inline-flex lg:flex-grow lg:w-auto ${
          isNavOpen ? "block" : "hidden"
        }`}
        id="navigation"
      >
        <div className="lg:inline-flex lg:flex-row lg:ml-auto lg:w-auto w-full lg:items-center items-start flex flex-col lg:h-auto">
          <button
            onClick={() => scrollToSection("flyxom")}
            className="lg:inline-flex lg:w-auto w-full px-3 py-2 rounded text-white items-center justify-center hover:bg-gray-900 hover:text-[#fcab21]"
          >
            {t("WIF.text")}
          </button>
          <button
            onClick={() => scrollToSection("us")}
            className="lg:inline-flex lg:w-auto w-full px-3 py-2 rounded text-white items-center justify-center hover:bg-gray-900 hover:text-[#fcab21]"
          >
            {t("US.text")}
          </button>
          <button className="lg:inline-flex lg:w-auto w-full px-3 py-2 rounded text-white items-center justify-center hover:bg-gray-900 hover:text-[#fcab21]">
            {Login()}
          </button>

          <button
            onClick={() => scrollToSection("contact")}
            className="lg:inline-flex lg:w-auto w-full px-3 py-2 rounded text-white items-center justify-center hover:bg-gray-900 hover:text-[#fcab21]"
          >
            {t("ContactH.text")}
          </button>

          <button
            onClick={() => changeLanguage("es")}
            className="lg:inline-flex lg:w-auto w-full px-3 py-2 rounded text-white items-center justify-center hover:bg-gray-900 hover:text-[#fcab21]"
          >
            ES
          </button>
          <button
            onClick={() => changeLanguage("en")}
            className="lg:inline-flex lg:w-auto w-full px-3 py-2 rounded text-white items-center justify-center hover:bg-gray-900 hover:text-[#fcab21]"
          >
            EN
          </button>
        </div>
      </div>
    </nav>
  );
}
