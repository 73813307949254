import Navbar from "./ContentHeader";
import Footer from "./ContentFooter";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";
import { initializeApp } from "firebase/app";
import { getDatabase, ref, set } from "firebase/database";

// Initialize Firebase
const firebaseConfig = {
  apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
  authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
  databaseURL: import.meta.env.VITE_FIREBASE_DATABASE_URL,
  projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
  storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
  appId: import.meta.env.VITE_FIREBASE_APP_ID,
  measurementId: import.meta.env.VITE_FIREBASE_MEASUREMENT_ID,
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
const database = getDatabase(firebaseApp);

export default function Survey() {
  const [t, i18n] = useTranslation("global");
  const navigate = useNavigate();
  const [userID, setUserID] = useState('');

  useEffect(() => {
    const storedLanguage = localStorage.getItem("language");
    if (storedLanguage) {
      i18n.changeLanguage(storedLanguage);
    }

    const user_ID = JSON.parse(localStorage.getItem("userID"));
    if (user_ID) {
      // Fetch user data based on UID
      setUserID(user_ID);
      
    } else  {
      // If user is not authenticated, redirect to login page
      console.log("UserId is not found.");
    }
  }, [i18n]);

  const saveSurveyData = async () => {
    event.preventDefault(); // Prevent the default form submission behavior

    
    
      try {
        if (
          document.getElementById("travelers")?.value &&
          document.getElementById("favoriteDestination")?.value &&
          document.getElementById("travelMonth")?.value &&
          document.getElementById("development")?.value
        ) {
          const travelers = document.getElementById("travelers").value;
          const favoriteDestination = document.getElementById("favoriteDestination").value;
          const luxuryOrSpace = document.getElementById("luxuryOrSpace").value;
          const weeksPerYear = document.getElementById("weeksPerYear").value;
          const otherMembership = document.getElementById("otherMembership").value;
          const travelMonth = document.getElementById("travelMonth").value;
          const development = document.getElementById("development").value;
          const db = getDatabase();
          set(ref(db, "surveys/" + userID), {
            travelers,
            favoriteDestination,
            luxuryOrSpace,
            weeksPerYear,
            otherMembership,
            travelMonth, 
            development, 
          });
          alert(t("Thanks.text"));
          navigate("/login");
        } else {
          alert(t("Please fil send all the required fields."));
        }
        } catch (error) {
          console.log("Error", error);
          alert(error);
        }

          
          

 
    
  };

  return (
    <>
      <Navbar />
      <div className="flex items-center justify-center w-full relative h-screen bg-center bg-no-repeat bg-[url('./assets/images/home.jpeg')]">
        <div className="overflow-y-auto max-h-screen bg-gray-800 bg-blend-multiply bg-opacity-50 shadow-md rounded-lg px-8 py-8 backdrop-filter backdrop-blur-md w-[40rem]">
          <h1 className="text-2xl font-bold text-center mb-4 dark:text-gray-200">
            {t("Survey.text")}
          </h1>
          <p className="border-b-2 border-gray-500"></p>
          <form action="#">
            <div className="pt-4 mb-4">
              <label
                htmlFor="travelers"
                className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2"
              >
                {t("FamilyTravelers.text")}
              </label>
              <input
                type="number"
                id="travelers"
                className="shadow-sm rounded-md w-full px-3 py-2 border-2 focus:outline-none focus:border-[#fcab21] focus:ring-[#fcab21]"
                required
              />
            </div>

            <div className="mb-4">
              <label
                htmlFor="favoriteDestination"
                className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2"
              >
                {t("FavoriteDestination.text")}
              </label>
              <input
                type="text"
                id="favoriteDestination"
                className="shadow-sm rounded-md w-full px-3 py-2 border-2 focus:outline-none focus:border-[#fcab21] focus:ring-[#fcab21]"
                required
              />
            </div>

            <div className="mb-4">
              <label
                htmlFor="luxuryOrSpace"
                className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2"
              >
                {t("LuxuryOrSpace.text")}
              </label>
              <select
                id="luxuryOrSpace"
                className="shadow-sm rounded-md w-full px-3 py-2 border-2 focus:outline-none focus:border-[#fcab21] focus:ring-[#fcab21]"
                required
              >
                <option value="luxury">{t("Luxury.text")}</option>
                <option value="space">{t("Space.text")}</option>
              </select>
            </div>

            <div className="mb-4">
              <label
                htmlFor="weeksPerYear"
                className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2"
              >
                {t("WeeksPerYear.text")}
              </label>
              <input
                type="number"
                id="weeksPerYear"
                className="shadow-sm rounded-md w-full px-3 py-2 border-2 focus:outline-none focus:border-[#fcab21] focus:ring-[#fcab21]"
                required
              />
            </div>

            <div className="mb-4">
              <label
                htmlFor="otherMembership"
                className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2"
              >
                {t("OtherMembership.text")}
              </label>
              <select
                id="otherMembership"
                className="shadow-sm rounded-md w-full px-3 py-2 border-2 focus:outline-none focus:border-[#fcab21] focus:ring-[#fcab21]"
                required
              >
                <option value="yes">{t("Yes.text")}</option>
                <option value="no">{t("No.text")}</option>
              </select>
            </div>

            <div className="mb-4">
              <label
                htmlFor="travelMonth"
                className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2"
              >
                {t("TravelMonth.text")}
              </label>
              <select
                id="travelMonth"
                className="shadow-sm rounded-md w-full px-3 py-2 border-2 focus:outline-none focus:border-[#fcab21] focus:ring-[#fcab21]"
                required
              >
                <option value="1">{t("January.text")}</option>
                <option value="2">{t("February.text")}</option>
                <option value="3">{t("March.text")}</option>
                <option value="4">{t("April.text")}</option>
                <option value="5">{t("May.text")}</option>
                <option value="6">{t("June.text")}</option>
                <option value="7">{t("July.text")}</option>
                <option value="8">{t("August.text")}</option>
                <option value="9">{t("September.text")}</option>
                <option value="10">{t("October.text")}</option>
                <option value="11">{t("November.text")}</option>
                <option value="12">{t("December.text")}</option>
              </select>
            </div>

            <div className="mb-4 pb-4">
              <label
                htmlFor="development"
                className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2"
              >
                {t("DevelopmentOrExclusive.text")}
              </label>
              <select
                id="development"
                className="shadow-sm rounded-md w-full px-3 py-2 border-2 focus:outline-none focus:border-[#fcab21] focus:ring-[#fcab21]"
                required
              >
                <option value="development">{t("Development.text")}</option>
                <option value="exclusive">{t("Exclusive.text")}</option>
              </select>
            </div>
            <p className="border-b-2 border-gray-500"></p>
            <button
              onClick={() => {saveSurveyData();
              }}
              className="mt-6 w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm text-white font-semibold bg-[#c79810] hover:bg-yellow-600 focus:outline-none focus:ring-2 focus:ring-offset-2"
            >
              {t("Send.text")}
            </button>
          </form>
        </div>
      </div>
      <Footer />
    </>
  );
}
